import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileUploaderService {
  private readonly upload$ = new BehaviorSubject<boolean | null>(null);
  readonly uploadAll$ = this.upload$.asObservable();

  triggerUpload(value: boolean) {
    this.upload$.next(value);
  }

  resetTrigger() {
    this.upload$.next(null);
  }
}
