import { computed } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { withLogger, withRequestStatus } from '@abbadox-monorepo/core-data-access';

import { IMAGE_CAPTURE, initialUploadWizardState } from './models/upload.models';

export const UploadWizardStore = signalStore(
  { providedIn: 'root' },
  withState(initialUploadWizardState),
  withRequestStatus(),
  withLogger('upload wizard state'),
  withComputed(({ step }) => ({
    frontStepVisible: computed(() => step() === IMAGE_CAPTURE.FRONT),
    backStepVisible: computed(() => step() === IMAGE_CAPTURE.BACK),
  })),
  withMethods((state) => {
    function setBackUploadStep(): void {
      patchState(state, { step: IMAGE_CAPTURE.BACK });
    }

    function resetUploadWizardStep(): void {
      patchState(state, { step: IMAGE_CAPTURE.FRONT });
    }

    function resetUploadWizard(): void {
      patchState(state, initialUploadWizardState);
    }

    function incrementUploadAttempts() {
      patchState(state, { uploadAttempts: state.uploadAttempts() + 1 });
    }

    return {
      setBackUploadStep,
      resetUploadWizard,
      resetUploadWizardStep,
      incrementUploadAttempts,
    };
  }),
);
