import { hyphenate } from '@abbadox-monorepo/core-utils';
import { KioskConfiguration } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { IconNames } from '@abbadox-monorepo/shared-ui/lib/components/icon';

import { KioskConfigurations } from './models/configs.model';
import { WIDGET_NAMES } from './models/widget.model';
import { Workflow } from './models/workflow.model';

type ConfigWorkflow = KioskConfiguration['startScreenWidgets'][0]['startScreenWidgetSections'][0]['workflows'][0];
type ConfigStep = ConfigWorkflow['steps'][0];
type ConfigStepWidget = ConfigStep['stepWidgets'][0];
type ConfigWidgetOverrides = Omit<ConfigStepWidget, 'sortOrder'>;
type ConfigWidget = ConfigStepWidget['widget'];

const mapWorkflow = (w: ConfigWorkflow) => {
  const sortOrder = 0; // reset step order to a manageable number
  const entities = mapSteps(w.steps, sortOrder);

  return {
    ...w,
    action: { ...w.action, route: hyphenate(w.action.actionName) },
    steps: { entities, total: w.steps.length },
  };
};

const mapSteps = (steps: ConfigStep[], sortOrder: number) => {
  return steps.map(mapStep(sortOrder)).sort((a, b) => a.sortOrder - b.sortOrder);
};

const mapStep = (sortOrder: number) => (s: ConfigStep) => {
  sortOrder++;

  return {
    ...s,
    stepWidgets: s.stepWidgets.map(mapStepWidget),
    sortOrder,
    route: hyphenate(s.stepName),
  };
};

const mapStepWidget = (sw: ConfigStepWidget) => {
  const { sortOrder, ...rest } = sw;

  return {
    ...sw,
    widget: mapWidget(rest, sw.widget),
  };
};

const mapWidget = (overrides: ConfigWidgetOverrides, w: ConfigWidget) => {
  return {
    ...w,
    icon: (overrides.iconOverride ?? w.icon) as IconNames,
    heading: overrides.headingOverride ?? w.heading,
    subHeading: overrides.subHeadingOverride ?? w.subHeading,
    accentColor: overrides.accentColorOverride ?? w.accentColor,
    failureMessage: overrides.failureMessageOverride ?? w.failureMessage,
  };
};

export const parseConfigs = (config: KioskConfiguration): KioskConfigurations => {
  let workflows: Workflow[] = [];

  const startScreenWidgets = config.startScreenWidgets.map((ssw) => ({
    ...ssw,
    startScreenWidgetSections: ssw.startScreenWidgetSections.map((ssws) => {
      workflows = ssws.workflows.map(mapWorkflow);
      return {
        ...ssws,
        workflows,
      };
    }),
  }));

  return {
    ...config,
    startScreenWidgets,
    workflows,
  };
};

export const parseSkippedFeatures = (
  workflow: Workflow,
  validations: {
    idsUploaded: boolean;
    insuranceUploaded: boolean;
  },
): Workflow => {
  let sortOrder = 0;

  const entities = workflow.steps.entities
    .filter(
      (step) =>
        !step.stepWidgets.some(
          (sw) =>
            (sw.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_FRONT && validations.idsUploaded) ||
            (sw.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_FRONT && validations.insuranceUploaded),
        ),
    )
    .map((step) => {
      sortOrder++;
      return { ...step, sortOrder };
    });

  return {
    ...workflow,
    steps: { entities, total: entities.length },
  };
};
