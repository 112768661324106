import { computed, inject } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { setLoaded, setLoading, withLogger, withRequestStatus } from '@abbadox-monorepo/core-data-access';
import { FileUploaderService } from '@abbadox-monorepo/core-file-uploader';
import { generateSecureSignature } from '@abbadox-monorepo/core-utils';
import { TOAST_DISMISS_DURATION_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import { UPLOADCARE_CONFIG } from '@abbadox-monorepo/kiosk-core-http-client';
import { IdsNotificationComponent, IdsToast, notificationStatusColorMap } from '@abbadox-monorepo/shared-ui';

import { FileUploadEventTrigger, initialFilesState } from './models/file.models';

export const FilesStore = signalStore(
  { providedIn: 'root' },
  withState(initialFilesState),
  withRequestStatus(),
  withLogger('files'),
  withComputed((state) => {
    const hasFile = computed(() => Boolean(state.file()));

    return { hasFile };
  }),
  withMethods(
    (
      state,
      toast = inject(IdsToast),
      fileUploaderService = inject(FileUploaderService),
      uploadcareConfig = inject(UPLOADCARE_CONFIG),
    ) => {
      function notifyUploadSuccess(): void {
        toast.openFromComponent(IdsNotificationComponent, {
          data: {
            ...notificationStatusColorMap.get('success'),
            ...{
              label: 'Upload Successful',
              messages: ['Image(s) successfully uploaded!'],
            },
          },
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: TOAST_DISMISS_DURATION_IN_MILLISECONDS,
        });
      }

      function notifyUploadFailed(): void {
        toast.openFromComponent(IdsNotificationComponent, {
          data: {
            ...notificationStatusColorMap.get('error'),
            ...{
              label: 'Upload Failed',
              messages: ['Image failed to upload. Please try again.'],
            },
          },
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: TOAST_DISMISS_DURATION_IN_MILLISECONDS,
        });
      }

      function generateSecureParams(): void {
        const secureParams = generateSecureSignature(uploadcareConfig.secret, {
          expire: uploadcareConfig.expires * 1000, // by the expiration timestamp in milliseconds since the epoch
        });

        patchState(state, { secureParams });
      }

      function addFile(file: string): void {
        patchState(state, { file });
      }

      function setUploading() {
        patchState(state, setLoading);
      }

      function setUploaded() {
        patchState(state, setLoaded);
      }

      function resetUpload(): void {
        patchState(state, { file: null });
        fileUploaderService.resetTrigger();
      }

      function resetFileState(): void {
        patchState(state, initialFilesState);
      }

      function uploadFile(event: FileUploadEventTrigger): void {
        if (!state.hasFile()) {
          return;
        }

        patchState(state, { uploadEventTrigger: event });
        fileUploaderService.triggerUpload(state.hasFile());
      }

      return {
        notifyUploadSuccess,
        notifyUploadFailed,
        addFile,
        setUploading,
        setUploaded,
        resetUpload,
        uploadFile,
        resetFileState,
        generateSecureParams,
      };
    },
  ),
);
