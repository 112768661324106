import { Workflow } from './workflow.model';

export type KioskConfigurations = {
  idleTimeoutInSeconds: number;
  sessionTimeoutInSeconds: number;
  authRetrySessionTimeoutInSeconds: number;
  formIdleTimeoutInSeconds: number;
  numberOfAuthRetryAttempts: number;
  idCardValidationCheckInMonths: number | null;
  startScreenWidgets: StartScreenWidget[];
  workflows: Workflow[];
};

export type StartScreenWidget = {
  startScreenWidgetId: number;
  heading: string | null;
  subHeading: string | null;
  icon: string | null;
  iconColor: string | null;
  startScreenWidgetSections: StartScreenWidgetSection[];
};

export type StartScreenWidgetSection = {
  heading: string | null;
  subHeading: string | null;
  startScreenWidgetSectionID: number;
  accentColor: string | null;
  workflows: Workflow[];
};

export type KioskConfigurationsState = Omit<KioskConfigurations, 'workflows'> & {
  currentStepRoute: string | undefined;
  idRecentlyUploaded: boolean;
  insuranceRecentlyUploaded: boolean;
};

export const initialKioskConfigurationsState: KioskConfigurationsState = {
  idleTimeoutInSeconds: 0,
  sessionTimeoutInSeconds: 0,
  authRetrySessionTimeoutInSeconds: 0,
  formIdleTimeoutInSeconds: 60,
  numberOfAuthRetryAttempts: 0,
  idCardValidationCheckInMonths: null,
  startScreenWidgets: [],
  currentStepRoute: undefined,
  idRecentlyUploaded: false,
  insuranceRecentlyUploaded: false,
};
