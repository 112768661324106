import { inject, Injectable, isDevMode, signal } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { ANALYTICS_API_URL, HttpBaseService } from '@abbadox-monorepo/core-http-client';
import {
  AnalyticsEventDTO,
  AnalyticsEventDTO as AnalyticsEventResponse,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';

import {
  PageViewEvent,
  AnalyticsEvent,
  ErrorLogEvent,
  ClickEvent,
  FormEvent,
  SearchQueryEvent,
  LoginAttemptEvent,
  CustomEvent,
  SessionEndEvent,
  SessionStartEvent,
} from './models/analytics.models';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private readonly apiBase = inject(HttpBaseService);
  private readonly apiUrl = inject(ANALYTICS_API_URL);

  private readonly analyticsConfig = signal<AnalyticsEventDTO>({
    date: '',
    userId: '',
    application: 'kiosk',
    callStack: '',
  });

  updateConfig(config: Partial<AnalyticsEventDTO>): void {
    this.analyticsConfig.update(() => ({ ...this.analyticsConfig(), ...config }));
  }

  track(event: 'page_view', data: PageViewEvent): Promise<AnalyticsEventResponse>;
  track(event: 'click', data: ClickEvent): Promise<AnalyticsEventResponse>;
  track(event: 'form_submission', data: FormEvent): Promise<AnalyticsEventResponse>;
  track(event: 'search', data: SearchQueryEvent): Promise<AnalyticsEventResponse>;
  track(event: 'login_attempt', data: LoginAttemptEvent): Promise<AnalyticsEventResponse>;
  track(event: 'session_start', data: SessionStartEvent): Promise<AnalyticsEventResponse>;
  track(event: 'session_end', data: SessionEndEvent): Promise<AnalyticsEventResponse>;
  track(event: 'error', data: ErrorLogEvent): Promise<AnalyticsEventResponse>;
  track(event: 'custom_event', data: CustomEvent): Promise<AnalyticsEventResponse>;
  track(event: string, data: AnalyticsEvent): Promise<AnalyticsEventResponse> {
    const eventData: AnalyticsEvent = {
      event,
      ...data,
      timestamp: new Date().toISOString(),
    };
    this.analyticsConfig.update(() => ({
      ...this.analyticsConfig(),
      date: new Date().toISOString(),
      callStack: JSON.stringify(eventData),
    }));
    const payload = this.analyticsConfig();

    if (isDevMode()) {
      console.log('AnalyticsService.track', event, data);
      return Promise.resolve(payload);
    }

    return lastValueFrom(this.apiBase.post<AnalyticsEventResponse, AnalyticsEventDTO>(`${this.apiUrl}/track`, payload));
  }

  trackPageView(data: PageViewEvent): Promise<AnalyticsEventResponse> {
    return this.track('page_view', data);
  }

  trackClick(data: ClickEvent): Promise<AnalyticsEventResponse> {
    return this.track('click', data);
  }

  trackFormSubmission(data: FormEvent): Promise<AnalyticsEventResponse> {
    return this.track('form_submission', data);
  }

  trackSearch(data: SearchQueryEvent): Promise<AnalyticsEventResponse> {
    return this.track('search', data);
  }

  trackLoginAttempt(data: LoginAttemptEvent): Promise<AnalyticsEventResponse> {
    return this.track('login_attempt', data);
  }

  trackSessionStart(data: SessionStartEvent): Promise<AnalyticsEventResponse> {
    return this.track('session_start', data);
  }

  trackSessionEnd(data: SessionEndEvent): Promise<AnalyticsEventResponse> {
    return this.track('session_end', data);
  }

  trackError(data: ErrorLogEvent): Promise<AnalyticsEventResponse> {
    return this.track('error', data);
  }

  trackCustomEvent(data: CustomEvent): Promise<AnalyticsEventResponse> {
    return this.track('custom_event', data);
  }
}
