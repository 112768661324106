import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';

import { AnalyticsService } from '@abbadox-monorepo/core-analytics';
import {
  setError,
  setLoaded,
  setLoading,
  withLogger,
  withRequestStatus,
  withSelectedEntity,
} from '@abbadox-monorepo/core-data-access';
import { FormsService } from '@abbadox-monorepo/core-forms';
import { hasAllValues } from '@abbadox-monorepo/core-utils';
import { TOAST_DISMISS_DURATION_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import {
  KioskCheckinFailedDialog,
  KioskDialogData,
  KioskPatientCheckinFailedDialogData,
  KioskPatientNotFoundDialog,
  PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS,
} from '@abbadox-monorepo/kiosk-ui';
import {
  defaultWidget,
  KioskConfigurationsStore,
  WIDGET_NAMES,
  WORKFLOW_NAMES,
} from '@abbadox-monorepo/kiosk-workflows-data-access';
import { IdsNotificationComponent, IdsToast, notificationStatusColorMap } from '@abbadox-monorepo/shared-ui';

import { initialPatientsState, Patient, PatientSearchParams } from './models/patient.model';
import { parsePatients } from './patient.parser';
import { PatientsHttpService } from './services/patients-http-client.service';

const normalizeLastName = (name: string) => name.toLowerCase().replace(/[\s'-]/g, ''); // Remove spaces, apostrophes, and dashes for comparison

const comparePhoneNumbers = (
  patientPhoneNumber: string,
  patientMobilePhoneNumber: string,
  inputPhoneNumber: string,
): boolean =>
  inputPhoneNumber ? patientPhoneNumber === inputPhoneNumber || patientMobilePhoneNumber === inputPhoneNumber : true;

const selectId: SelectEntityId<Patient> = (patient) => patient.autoCount;

export const PatientsSearchStore = signalStore(
  { providedIn: 'root' },
  withState(initialPatientsState),
  withEntities<Patient>(),
  withSelectedEntity(),
  withRequestStatus(),
  withLogger('patients search'),
  withComputed((state, kioskConfigurationsStore = inject(KioskConfigurationsStore)) => {
    const searchedPatients = computed(() =>
      state.entities().filter((p) => {
        const { lastName, phoneNumber, dateOfBirth } = state.filters().params;
        const lastNameQuery = normalizeLastName(lastName);
        const phone = comparePhoneNumbers(p.phone, p.mobilePhone, phoneNumber);

        return normalizeLastName(p.patientLast).includes(lastNameQuery) && p.ptDOB === dateOfBirth && phone;
      }),
    );
    const patientWidget = computed(
      () =>
        kioskConfigurationsStore
          .activeStepWidgets()
          .find((sw) => new RegExp(WIDGET_NAMES.AUTHENTICATION, 'i').test(sw.widget.widgetName)) ?? defaultWidget,
    );
    const failureMessage = computed(
      () => patientWidget().widget.failureMessage ?? patientWidget().failureMessageOverride,
    );

    return { searchedPatients, patientWidget, failureMessage };
  }),
  withMethods(
    (
      state,
      patientsHttpService = inject(PatientsHttpService),
      kioskConfigurationsStore = inject(KioskConfigurationsStore),
      formsService = inject(FormsService),
      dialog = inject(MatDialog),
      toast = inject(IdsToast),
      analyticsService = inject(AnalyticsService),
    ) => {
      function resetAttempts(): void {
        patchState(state, { attempts: 0 });
      }

      function resetPatientSearchState(): void {
        patchState(state, { ...initialPatientsState, selectedEntityId: null });
      }

      function showErrorNotificationToast(): void {
        toast.openFromComponent(IdsNotificationComponent, {
          data: {
            ...notificationStatusColorMap.get('error'),
            label: 'No Match Found',
            messages: [state.failureMessage()],
          },
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: TOAST_DISMISS_DURATION_IN_MILLISECONDS,
        });
      }

      function showNoPatientFoundDialog(): void {
        dialog.open<KioskPatientNotFoundDialog, KioskDialogData>(KioskPatientNotFoundDialog, {
          data: { title: 'No Existing Patient Found' },
        });
      }

      function showFailedAttemptsDialog(): void {
        dialog
          .open<KioskCheckinFailedDialog, KioskPatientCheckinFailedDialogData>(KioskCheckinFailedDialog, {
            data: {
              title: 'Authentication Failed',
              maxTimeout: kioskConfigurationsStore.authRetrySessionTimeoutInSeconds(),
              maxAttempts: kioskConfigurationsStore.numberOfAuthRetryAttempts(),
              sessionTimeout: kioskConfigurationsStore.sessionTimeoutInSeconds(),
            },
            disableClose: true,
          })
          .afterClosed()
          .subscribe((event) => {
            if (
              [
                PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS.CLOSE_BUTTON,
                PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS.TIMEOUT,
                PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS.CLOSE_ICON,
              ].includes(event)
            ) {
              resetPatientSearchState();
              kioskConfigurationsStore.resetWorkflow();
            }
          });
      }

      function updateSearchParams(params: PatientSearchParams): void {
        patchState(state, {
          filters: {
            ...state.filters(),
            params: {
              ...state.filters.params(),
              ...params,
            },
          },
        });
      }

      function checkFormValidation(): void {
        const form = formsService.getForm();

        if (form) {
          formsService.validateFormControls();
          patchState(state, { filters: { ...state.filters(), valid: form.valid } });
        }
      }

      async function loadPatients(appointmentPatientIds: string[] | number[]): Promise<void> {
        if (!appointmentPatientIds.length) {
          return;
        }

        try {
          const { patients } = await patientsHttpService.finaPatientsByIdsAsPromise({
            patientIds: appointmentPatientIds.join(','),
          });
          patchState(state, setAllEntities(parsePatients(patients), { selectId }), setLoaded());
        } catch (error) {
          const errorResponse = error as HttpErrorResponse;
          patchState(state, setError(errorResponse.message));
        }
      }

      async function searchPatients(): Promise<void> {
        const params = state.filters().params;

        if (!state.filters().valid && !hasAllValues(params)) {
          return;
        }

        patchState(state, setLoading());

        const query = JSON.stringify(params);
        await analyticsService.trackCustomEvent({ event_name: 'search_query', query });

        const patients = state.searchedPatients();

        // failed to find a patient
        if (!patients.length) {
          const workflow = kioskConfigurationsStore.selectedEntityId();

          patchState(state, { attempts: state.attempts() + 1 }, setError('No patients found.'));

          if (state.attempts() < kioskConfigurationsStore.numberOfAuthRetryAttempts()) {
            if (workflow === WORKFLOW_NAMES.CHECK_IN) {
              showErrorNotificationToast();
            } else if (workflow === WORKFLOW_NAMES.WALK_IN) {
              showNoPatientFoundDialog();
            }
          } else if (state.attempts() === kioskConfigurationsStore.numberOfAuthRetryAttempts()) {
            showFailedAttemptsDialog();
          }

          return;
        }

        // found multiple patients
        if (patients.length > 1) {
          await analyticsService.trackSearch({ result: '', duplicateResults: true });
          patchState(state, { duplicate: true, sessionStarted: true, attempts: 0 }, setLoaded());

          return;
        }

        // found a single patient
        const patient = patients[0];
        const result = patient ? JSON.stringify(patient) : '';
        await analyticsService.trackSearch({ result, duplicateResults: false });

        patchState(
          state,
          { selectedEntityId: patient.autoCount, duplicate: false, sessionStarted: true, attempts: 0 },
          setLoaded(),
        );
      }

      async function validateAndSearchPatients(): Promise<void> {
        checkFormValidation();
        await searchPatients();
      }

      return {
        loadPatients,
        updateSearchParams,
        resetAttempts,
        resetPatientSearchState,
        validateAndSearchPatients,
      };
    },
  ),
);
