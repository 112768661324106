import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, forkJoin, pipe, filter, tap, switchMap } from 'rxjs';

import { setError, setLoaded, setLoading, withLogger, withRequestStatus } from '@abbadox-monorepo/core-data-access';
import {
  AppointmentGroup,
  AppointmentsHttpService,
  AppointmentsStore,
} from '@abbadox-monorepo/kiosk-appointments-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';
import { CommentedEntityType, CommentType, PatientCommentsDTO } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import {
  KioskWrongLocationDialog,
  KioskWrongLocationDialogData,
  WRONG_LOCATION_DIALOG_TRIGGERS,
  KioskAppointmentsNotFoundDialog,
  KioskAppointmentsNotFoundDialogData,
  APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS,
} from '@abbadox-monorepo/kiosk-ui';
import { KioskConfigurationsStore } from '@abbadox-monorepo/kiosk-workflows-data-access';

import { initialPatientAppointmentComments, Patient } from './models/patient.model';
import { PatientsSearchStore } from './patient-search.store';
import { PatientsHttpService } from './services/patients-http-client.service';

export const PatientDetailsStore = signalStore(
  { providedIn: 'root' },
  withState(initialPatientAppointmentComments),
  withRequestStatus(),
  withLogger('patient details'),
  withComputed(
    (
      _,
      authStore = inject(AuthStore),
      appointmentsStore = inject(AppointmentsStore),
      patientsSearchStore = inject(PatientsSearchStore),
    ) => {
      const patientAppointments = computed(() =>
        appointmentsStore.entities().filter((a) => a.patientId == patientsSearchStore.selectedEntityId()),
      );
      const appointmentsWithCorrectStatus = computed(() => patientAppointments().length > 0);
      const patientAppointmentsAtCorrectLocation = computed(() =>
        patientAppointments().filter((a) => Number(a.locationId) === Number(authStore.currentLocation().locationId)),
      );
      const appointmentLocations = computed(() =>
        patientAppointments().map(({ locationId, locationName }) => ({ locationId: Number(locationId), locationName })),
      );
      const appointmentLocation = computed(() => appointmentLocations()[0]);
      const appointmentsAtCorrectLocation = computed(() =>
        appointmentLocations().some((a) => Number(a.locationId) === Number(authStore.currentLocation().locationId)),
      );
      const appointmentIdsAsNumbers = computed(() =>
        patientAppointmentsAtCorrectLocation().map((a) => Number(a.appointmentId)),
      );
      const appointmentIdsAsString = computed(() => appointmentIdsAsNumbers().join(','));
      const basePatientUploadMetadata = computed(() => ({
        accountName: authStore.accountName() ?? '',
        patientID: String(patientsSearchStore.selectedEntityId() ?? ''),
        appointmentIDs: appointmentIdsAsString(),
      }));
      const idImageMetadata = computed(() => ({ ...basePatientUploadMetadata(), workType: authStore.idsWorktype() }));
      const insuranceImageMetadata = computed(() => ({
        ...basePatientUploadMetadata(),
        workType: authStore.insuranceWorktype(),
      }));

      return {
        patientAppointments,
        appointmentsWithCorrectStatus,
        patientAppointmentsAtCorrectLocation,
        appointmentsAtCorrectLocation,
        appointmentLocation,
        appointmentIdsAsNumbers,
        idImageMetadata,
        insuranceImageMetadata,
      };
    },
  ),
  withMethods(
    (
      state,
      patientsHttpService = inject(PatientsHttpService),
      appointmentsHttpService = inject(AppointmentsHttpService),
      authStore = inject(AuthStore),
      kioskConfigurationsStore = inject(KioskConfigurationsStore),
      patientsSearchStore = inject(PatientsSearchStore),
      dialog = inject(MatDialog),
    ) => {
      function showWronglocationDialog(): void {
        const currentLocation = authStore.currentLocation().locationName;
        const sessionTimeout = kioskConfigurationsStore.sessionTimeoutInSeconds();

        dialog
          .open<KioskWrongLocationDialog, KioskWrongLocationDialogData>(KioskWrongLocationDialog, {
            data: {
              title: "You're at the wrong location",
              scheduledLocation: state.appointmentLocation().locationName,
              currentLocation,
              maxTimeout: sessionTimeout,
              sessionTimeout,
            },
          })
          .afterClosed()
          .subscribe((event) => {
            if ([WRONG_LOCATION_DIALOG_TRIGGERS.TIMEOUT, WRONG_LOCATION_DIALOG_TRIGGERS.CLOSE_BUTTON].includes(event)) {
              kioskConfigurationsStore.resetWorkflow();
            }
          });
      }

      function showWrongAppointmentStatus(sessionTimeout: number): void {
        dialog
          .open<KioskAppointmentsNotFoundDialog, KioskAppointmentsNotFoundDialogData>(KioskAppointmentsNotFoundDialog, {
            data: { title: 'No Appointments Today', maxTimeout: sessionTimeout, sessionTimeout },
          })
          .afterClosed()
          .subscribe((event) => {
            if (event === APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS.TIMEOUT) {
              kioskConfigurationsStore.resetWorkflow();
            }
          });
      }

      function checkPatientAppointments(): void {
        if (!patientsSearchStore.selectedEntityId()) {
          return;
        }

        if (!state.appointmentsWithCorrectStatus()) {
          showWrongAppointmentStatus(kioskConfigurationsStore.sessionTimeoutInSeconds());
          return;
        }

        if (!state.appointmentsAtCorrectLocation()) {
          showWronglocationDialog();
        }
      }

      function addComment(type: CommentType, comment: string): void {
        patchState(state, { comments: { ...state.comments(), [type]: comment } });
      }

      function resetPatientCommentsState(): void {
        patchState(state, initialPatientAppointmentComments);
      }

      async function saveComments(patient: Patient, type: CommentType): Promise<void> {
        const commentText = state.comments()[type];

        if (!commentText) {
          return;
        }

        const params: PatientCommentsDTO = {
          patientMRN: patient.mrn,
          appointmentIDs: state.appointmentIdsAsNumbers(),
          commentText,
          commentType: type,
          commentedEntityType: CommentedEntityType.Appointment,
        };

        try {
          await patientsHttpService.updatePatientRecordsCommentsAsPromise(String(patient.autoCount), params);
          resetPatientCommentsState();
        } catch (error) {
          if (error instanceof HttpErrorResponse) {
            patchState(state, setError(error.message));
          }
        }
      }

      async function savePatientComments(patient: Patient | null): Promise<void> {
        if (!patient) {
          return;
        }

        await saveComments(patient, 'patient');
      }

      async function saveAppointmentComments(patient: Patient | null): Promise<void> {
        if (!patient) {
          return;
        }

        await saveComments(patient, 'appointment');
      }

      function assembleEventRequests(appointments: AppointmentGroup[]): Observable<string[]> {
        const requests = appointments
          .map(({ appointmentId }) => ({
            accountName: authStore.accountName(),
            appointmentId: Number(appointmentId),
            newStatusId: kioskConfigurationsStore.appointmentStatusChangeUponCompletion(),
            applyToAllAppointments: false,
          }))
          .map((payload) => appointmentsHttpService.createNewAppointmentEvent(payload));

        return forkJoin(requests);
      }

      const createNewAppointmentsEvent = rxMethod<void>(
        pipe(
          filter(() => state.patientAppointmentsAtCorrectLocation().length > 0),
          tap(() => patchState(state, setLoading())),
          switchMap(() =>
            assembleEventRequests(state.patientAppointmentsAtCorrectLocation()).pipe(
              tapResponse({
                next: () => patchState(state, setLoaded),
                error: (error: HttpErrorResponse) => patchState(state, setError(error.message)),
              }),
            ),
          ),
        ),
      );

      return {
        checkPatientAppointments,
        addComment,
        resetPatientCommentsState,
        savePatientComments,
        saveAppointmentComments,
        createNewAppointmentsEvent,
      };
    },
  ),
);
