import { PatientInfo } from '@abbadox-monorepo/kiosk-core-api-interfaces';

import { Patient } from './models/patient.model';

export const parsePatients = (patients: PatientInfo[]): Patient[] => {
  if (!patients.length) {
    return [];
  }

  return patients.map((p) => ({
    autoCount: p.autoCount,
    mrn: p.mrn ?? '',
    patientFirst: p.patientFirst ?? '',
    patientMiddle: p.patientMiddle ?? '',
    patientLast: p.patientLast ?? '',
    sex: p.sex ?? '',
    ptDOB: p.ptDOB ?? '',
    phone: p.phone ?? '',
    mobilePhone: p.mobilePhone ?? '',
    email: p.email ?? '',
    address: p.address ?? '',
    address2: p.address2 ?? '',
    city: p.city ?? '',
    state: p.state ?? '',
    zipcode: p.zipcode ?? '',
  }));
};
