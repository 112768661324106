import { OutputFileEntry } from '@uploadcare/file-uploader';

import { UploadSecureSignature } from '@abbadox-monorepo/core-utils';

export type UploadFile = OutputFileEntry<'success'>;

export type FileUploadEventTrigger = 'next_click' | 'extra_click';

export type FilesState = {
  uploadEventTrigger: FileUploadEventTrigger | null;
  file: string | null;
  secureParams: UploadSecureSignature;
};

export const initialFilesState: FilesState = {
  uploadEventTrigger: null,
  file: null,
  secureParams: {
    secureSignature: '',
    secureExpire: '',
  },
};
