import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods } from '@ngrx/signals';
import { removeAllEntities, SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';

import {
  setError,
  setLoaded,
  setLoading,
  withLogger,
  withRequestStatus,
  withSelectedEntity,
} from '@abbadox-monorepo/core-data-access';

import { parseAppointments } from './appointments.parser';
import { AppointmentGroup } from './models/appointments.model';
import { AppointmentsHttpService } from './services/appointments-http-client.service';

const selectId: SelectEntityId<AppointmentGroup> = (appoitnment) => appoitnment.appointmentId;

export const AppointmentsStore = signalStore(
  { providedIn: 'root' },
  withEntities<AppointmentGroup>(),
  withSelectedEntity(),
  withRequestStatus(),
  withLogger('appointments'),
  withComputed((state) => {
    const patientsWithAppointments = computed(() => state.entities().map(({ patientId }) => Number(patientId)));

    return { patientsWithAppointments };
  }),
  withMethods((state, appointmentsHttpService = inject(AppointmentsHttpService)) => {
    function resetAppointentsState(): void {
      patchState(state, removeAllEntities(), { selectedEntityId: null });
    }

    async function loadAppointments(allowableStatuses: string): Promise<void> {
      if (!allowableStatuses) {
        return;
      }

      patchState(state, setLoading);

      try {
        const appointments = await appointmentsHttpService.findAppointmentsAsPromise({
          statusFilters: allowableStatuses,
        });

        patchState(state, setAllEntities(parseAppointments(appointments), { selectId }), setLoaded);
      } catch (err) {
        const error = err as HttpErrorResponse;
        patchState(state, setError(error.message));
      }
    }

    return { resetAppointentsState, loadAppointments };
  }),
);
