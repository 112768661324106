import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import { CareflowApiHttpService } from '@abbadox-monorepo/core-http-client';
import {
  EformsUrlResponse,
  EformsPayloadDto,
  EformsStatusQuery,
  Eform,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { KioskApiHttpService } from '@abbadox-monorepo/kiosk-core-http-client';

@Injectable({
  providedIn: 'root',
})
export class EformsHttpService {
  private readonly kioskApiHttpService = inject(KioskApiHttpService);
  private readonly careflowApiHttpService = inject(CareflowApiHttpService);

  /**
   * Creates a URL to launch forms.
   *
   * @param payload - payload to send to generate forms
   * @returns - url to embed
   */
  openEformsApi(payload: EformsPayloadDto): Observable<EformsUrlResponse> {
    return this.careflowApiHttpService.post<EformsUrlResponse, EformsPayloadDto>(
      '/AppointmentsApi/GenerateKioskEFormLauncherUrl',
      payload,
    );
  }

  openEformsApiAsPromise(payload: EformsPayloadDto): Promise<EformsUrlResponse> {
    return lastValueFrom(this.openEformsApi(payload));
  }

  /**
   * Checks the status of forms completed state.
   *
   * @param query - query params to search for a patient's forms status
   * @returns - eforms completion status
   */
  checkEformsStatus(query: EformsStatusQuery): Observable<Eform> {
    let params = new HttpParams();

    for (const [key, value] of Object.entries(query)) {
      if (key && value) {
        params = params.append(key, value);
      }
    }

    return this.kioskApiHttpService.get<Eform>('/eforms/status', params);
  }
}
