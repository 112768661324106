export const enum IMAGE_CAPTURE {
  FRONT = 'front',
  BACK = 'back',
}

export type UploadWizardState = {
  step: IMAGE_CAPTURE;
  uploadAttempts: number;
};

export const initialUploadWizardState: UploadWizardState = {
  step: IMAGE_CAPTURE.FRONT,
  uploadAttempts: 0,
};

export type DocumentParams = Record<string, string>;

export type UploadMetadata = {
  accountName: string;
  patientID: string;
  appointmentIDs: string;
  workType: string;
};
